.table-container {
  max-height: 300px; /* Set a maximum height for the table container */
  overflow-y: auto;  /* Enable vertical scrolling */
  margin: 20px 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
  border-radius: 8px;
  /* border: 1px solid #ddd; Add border to the table container */
}
h2{
  margin:20px 0;
}
.manually-table {
  width: 100%;
  border-collapse: collapse;
  /* margin: 20px 0; */
  font-size: 1em;
  text-align: left;
}

.manually-table thead tr {
  background-color: #686869;
  color: white;
  position: sticky; /* Keep the header sticky while scrolling */
  top: 0;
}

.manually-table th,
.manually-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
}

.manually-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.manually-table tbody tr:hover {
  background-color: #ddd;
}

.filters input {
  margin: 5px;
  padding: 5px;
}
@media (max-width: 768px) {
  .manually-table {
    font-size: 0.9em; /* Smaller font size for small screens */
  }

  .filters {
    flex-direction: column; /* Stack filter inputs vertically */
  }
}

@media (max-width: 480px) {
  .manually-table {
    font-size: 0.8em; /* Smaller font size for very small screens */
  }

  .filters input {
    width: 100%; /* Full width for each input */
  }
}

/* .pagination {
  margin: 20px 0;
  text-align: center;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  border: none;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
}

.pagination button.active {
  background-color: #45a049;
}

.pagination button:hover:not(.active) {
  background-color: #45a049;
} */
