.content-area-charts {
    display: grid;
    grid-template-columns:100%;
    gap: 16px;
    
  
    /* @media (max-width: 992px) {
      grid-template-columns: 100%;
    } */
  }
  
  .bar-chart , .pie-chart {
    background-color: #fff;
    padding: 16px 16px 6px 16px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
    position: relative;
  
    @media (max-width: 768px) {
      padding: 12px;
    }
  
    .bar-chart-wrapper ,.pie-chart-wrapper {
      width: 100%;
      height: auto;
  
      .recharts-wrapper {
        @media (max-width: 1400px) {
          margin-left: -10px;
        }
      }
    }
  
    .bar-chart-info {
      text-align: center;
      margin-bottom: 32px;
    }
  
    .bar-chart-title {
      margin-bottom: 12px;
      font-size: 1.5rem;
      font-weight: 700;
      color: #312e2e;
    }
  
    .recharts-default-legend {
      position: absolute;
      top: -90px;
      right: 0;
    }
    .recharts-tooltip-label {
      color: #292929;
      font-size: 14px;
      font-weight: 600;
    }
    /* .pie-chart {
      width: 100%; 
      height: 100%;
      margin-bottom: 30px; 
    } */
    
    .pie-chart-info {
      text-align: center;
      margin-bottom: 20px;
    }
    
    .pie-chart-title {
      font-size: 1.5rem;
      color: #333;
      margin: 0;
    }
    
    
    /* Tooltip and Legend Adjustments for PieChart */
    .pie-chart .recharts-default-tooltip {
      border-radius: 4px;
      background-color: #ffffff;
      padding: 10px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
      font-size: 0.9rem;
      color: #333;
    }
    
    
    
    .pie-chart .recharts-legend-item {
      margin-bottom: 12px; /* Add space between legend items */
      display: flex; /* Use flex to align items properly */
      align-items: center; 
    }
    
    
  }
  