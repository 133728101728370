.stats-table-container {
    margin: 10px;
    padding: 20px;
    /* border: 1px solid #ccc; */
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
    border-radius: 8px;
    background-color: #f9f9f9;
    overflow-x: auto; /* Enable horizontal scrolling on small screens */
  }
  .stats-table-title {
    font-size: 2rem;
    font-weight: bold;
    flex-grow: 1; 
    text-align: center;
    margin: 20px;
    color:#28282c;
  }
  .stats-btn{
    width:30%;
  }
  .stats-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    min-width: 800px; /* Minimum width to prevent the table from collapsing too much */
  }
  
  .stats-table th,
  .stats-table td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
  }
  
  .stats-table th {
    background-color: #686869;
    color: white;
  }
  
  .stats-table td {
    background-color: #f2f2f2;
  }
  
  .stats-table tr:hover {
    background-color: #ddd;
  }
  .dt-cell-action {
    text-align: center;
  }

  .dt-status {
    display: flex;
    align-items: center;
    justify-content: space-around;
    column-gap: 6px;

    .dt-status-dot {
      width: 8px;
      height: 8px;
      border-radius: 100%;

      &.dot-Away {
        background-color: #f29a2e;
      }
      &.dot-Offline {
        background-color: #ef0606;
      }
      &.dot-Online {
        background-color: #4ce13f;
      }
    }

    .dt-status-text {
      text-transform: capitalize;
    }
  }

  
  /* Responsive styles */
  @media (max-width: 768px) {
    .stats-table th,
    .stats-table td {
      padding: 8px; /* Reduce padding */
      font-size: 12px; /* Reduce font size */
    }
  
    .stats-table {
      min-width: 600px; /* Reduce minimum width for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .stats-table th,
    .stats-table td {
      padding: 6px; /* Further reduce padding */
      font-size: 10px; /* Further reduce font size */
    }
  
    .stats-table {
      min-width: 400px; /* Further reduce minimum width */
    }
  
    .stats-table-container {
      padding: 10px; /* Reduce container padding */
    }
  }
  