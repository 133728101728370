@tailwind base;
@tailwind components;
@tailwind utilities;
 

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');

*{
  font-family: "font-libre font-bold capitalize";
}

.princefont{
  font-family: "Libre Baskerville" !important;
}

.imp
{
  color : red !important;
  font-weight: bolder !important;
}

.fade-slide-enter {
  opacity: 0;
  transform: translateX(100%);
}
.fade-slide-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
}
.fade-slide-exit {
  opacity: 1;
  transform: translateX(0);
}
.fade-slide-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
}

a{
  text-decoration: none !important;
  text-decoration-line: none  !important;
} 
 @layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
} 

.photos
{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}
.photo
{
  position: relative;
  

  overflow : hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo img
{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.together {
  width: 250px;
  height: 150px;
}
/* 
.alone {
  padding: 40px 100px;
  border-radius: 10px;
} */
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
}


.del_btn
{
  position: absolute;
  right: 0;
  top: 0;
  padding: 3px;
  border: none;
  background-color: rgba(255, 255, 255, 1);
  font-size: 20px;
  cursor: pointer;
}
 /* @layer base {
  body {
    @apply font-primary;
  }
}  */

#x:hover
{
  background-color: #f1f5f9;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader-inner {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



.slick-slide > div{
  margin : 0 20px;
}
.slick-prev,
.slick-next
{
  background-color : rgb(17 24 39) !important;
  border-radius: 50%;
  width:30px !important;
  height: 30px !important;
  
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 50 !important;
 opacity: 1 !important;
} 


.slick-arrow.slick-next,
.slick-arrow.slick-prev{

display: flex;
align-items: center;
justify-content: center;
/* border-radius: 50%; */
opacity: 1;
height:30px;
width:30px;
z-index : 10;
}

.slick-arrow.slick-next:hover,
.slick-arrow.slick-prev:hover
{
    opacity:1;
}

/* =============================== ADMIN (SK) =============================== */


a {
  text-decoration: none;
}

button {
  background-color: transparent;
 /* border: none; */
  outline: 0;
  cursor: pointer;
}

ul {
  list-style-type: none;
}

 /* theme toggle button */
.theme-toggle-btn {
  background-color: #ffffff;
  position: fixed;
  right: 0;
  top: 20px;
  width: 48px;
  height: 40px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: 0;
  z-index: 999;
  cursor: pointer;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.theme-icon {
    width: 24px;
  }

.page-wrapper {
  background-color: #fafafa;
  min-height: 100vh;
  overflow: hidden;
}

.content-wrapper {
  background-color: #fafafa;
  min-height: 100vh;
  padding: 50px 50px;
  margin-left: 260px;

  @media (max-width: 1400px) {
    padding: 32px;
  }

  @media (max-width: 1200px) {
    margin-left: 56px;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    padding: 24px;
  }

  @media (max-width: 576px) {
    margin-left: 0;
    padding: 16px;
  }
}

 /* content */
.content-area {
  & > section {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}