.profile {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  background-color: #f4f4f4;
  padding: 10px;
}

.user-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  max-width: 100%; 
  width: 100%;  
  box-sizing: border-box;
  height: auto;
}

.user-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.user-direction{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content : space-around
}
.profile-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #070809;
}

.upload-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.camera-icon {
  font-size: 24px;
  color: #080809;
}

.username {
  font-size: 1.75rem;
  font-weight: bold;
  color: #333;
  margin: 10px 0;
}

.additional-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items : space-between;
  justify-content: center;

}

.info-item {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}

.info-label {
  /* padding-left: 40%; */
  font-weight: bold;
  color: #333;
  font-size: 1rem;
}
.info-sub {
  font-weight: bold;
  color: #333;
  font-size: 1rem
}
.info-value {
  color: #666;
  font-size: 1rem;
}
.button-adjust{
  display:flex;
  align-items: center;
  justify-content: space-between;
}
.edit-button, .save-button, .cancel-button {
  margin: 20px 0;
  padding: 12px 25px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, color 0.3s;
}

.edit-button {
  margin-top:40px;
  background-color: #424457;
  color: #fff;
}

.edit-button:hover {
  background-color: #5f617a;
}

.save-button {
  background-color: #424457;
  color: #fff;
}

.save-button:hover {
  background-color: #5f617a;
}

.cancel-button {
  background-color: #424457;
  color: #fff;
  margin-left: 10px;
}

.cancel-button:hover {
  background-color: #5f617a;
}

.edit-form {
  display: flex;
  flex-direction: column;
  width: 70%;
}
.edit-form label {
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
}

.form-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.edit-form input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.edit-form input:focus {
  border-color: #007bff;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .profile {
    padding: 10px;
  }

  .user-profile {
    padding: 20px;
    max-width: 100%;
  }

  .profile-image {
    width: 120px;
    height: 120px;
  }

  .username {
    font-size: 1.5rem;
  }

  .email {
    font-size: 1rem;
  }

  .info-label, .info-value {
    font-size: 1rem;
  }

  .edit-button, .save-button, .cancel-button {
    font-size: 1rem;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .profile {
    padding: 5px;
  }

  .user-profile {
    padding: 15px;
  }

  .profile-image {
    width: 100px;
    height: 100px;
  }

  .username {
    font-size: 1.25rem;
  }

  .email {
    font-size: 0.9rem;
  }

  .info-label, .info-value {
    font-size: 0.9rem;
  }

  .edit-button, .save-button, .cancel-button {
    font-size: 0.9rem;
    padding: 8px 15px;
  }

  .edit-form input {
    font-size: 1rem;
  }
}

