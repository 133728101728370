.content-area-table {
    background: #fff;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
    padding: 12px 16px;
  
    @media (max-width: 768px) {
      padding: 12px;
    }
  
    .data-table-title {
      font-size: 2rem;
      font-weight: bold;
      flex-grow: 1; 
      text-align: center;
      margin: 20px;
      color:#11a3a5;
    }
    .table-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    
    /* Styling for the select box */
    .table-select {
      padding: 8px;
      font-size: 14px;
      border: 1px solid #ccc;
      border-radius: 4px;
      /* margin-right: 20px; Space between select box and title */

    }
  
    .table-search-container {
      display: flex;
      align-items: center;
      width: 250px; /* Adjust width as needed */
    }
    .table-search {
      padding: 8px;
      font-size: 14px;
      border: 1px solid #ccc;
      border-radius: 4px 0 0 4px;
      width: 200px; /* Adjust width as needed */
    }
    .table-search-btn {
      padding: 8px 16px;
      border: 1px solid #11a3a5;
      border-radius: 0 4px 4px 0; /* Rounded corners on the right side */
      background-color: #11a3a5;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    .table-search-btn:hover {
      background-color: #74afca;
    }
    
    .table-search-btn svg {
      font-size: 16px; /* Adjust icon size */
      color: #333; /* Icon color */
    }
    .data-table-diagram {
      /* border-radius: 6px; */
      border: 2px solid #b9b9ba !important;
      overflow-x: scroll;
  
      /* &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
      }
  
      &::-webkit-scrollbar-track {
        background-color: #fff;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 100vh !important;
        background-color: #e9e9e9;
        outline: 1px solid rgba(0, 0, 0, 0.02);
        outline-offset: -1px;
      } */
  
      table {
        min-width: 900px;
        width: 100%;
        border-collapse: collapse;
        /* color: #e9e9e9; */
  
        
        th {
          padding: 14px 12px;
          @media (max-width: 768px) {
            font-size: 15px;
            padding: 12px 10px;
          }
        }
  
        thead {
          text-align: center;
          font-size: 17px;
          background-color: #b9b9ba;
  
          /* th {
            &:last-child {
              text-align: center;
            }
          } */
        }
        tbody{
          text-align: center;
        }
        td{
          border:1px solid #b9b9ba;
          padding: 0px 10px;
        }
        .dt-cell-action {
          text-align: center;
        }
  
        .dt-status {
          display: flex;
          align-items: center;
          justify-content: space-around;
          column-gap: 6px;
  
          .dt-status-dot {
            width: 8px;
            height: 8px;
            border-radius: 100%;
  
            &.dot-pending {
              background-color: #f29a2e;
            }
            &.dot-canceled {
              background-color: #ef0606;
            }
            &.dot-delivered {
              background-color: #4ce13f;
            }
          }
  
          .dt-status-text {
            text-transform: capitalize;
          }
        }
      }
    }
  
    .action-dropdown-btn {
      margin:5px;
      padding: 2px 5px;
      color: #11a3a5;
      position: relative;
      border-radius:40px ;
      border:2px solid #11a3a5;
      background-color: transparent;
      .button-font {
        font-weight: bold;
        font-size: 13px;
        padding:5px;
      }
    }
  
    /* .action-dropdown-menu {
      position: absolute;
      top: 100%;
      right: 50%;
      transform: translateX(50%);
      height: auto;
      min-width: 80px;
      box-shadow: rgba(100, 100, 111, 0.1) 0px 1px 4px 0px;
      background-color: #fff;
      padding: 6px 10px;
      z-index: 1;
      border-radius: 8px;
      border: 1px solid #f6f6f6;
      text-align: left;
    } */
  
    .dropdown-menu-item {
      margin: 8px 0;
    }
  
    .dropdown-menu-link {
      color: #111111;
      font-weight: 600;
      transition: all 300ms ease-in-out;
  
      &:hover {
        opacity: 0.9;
      }
    }
  }