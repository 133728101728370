
.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    width: 260px;
    height: 100%;
    background-color: #fff;
    padding: 20px 14px;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    z-index: 999;
    transition: all 300ms ease-in-out;
    overflow-y: scroll;
  
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      /* background-color: #2e2e48; */
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 100vh !important;
      background-color: #575777;
      outline: 1px solid rgba(0, 0, 0, 0.02);
      outline-offset: -1px;
    }
  
     /* toggling this class helps to hide/unhide the sidebar */
    &.sidebar-show{
      @media(max-width: 768px){
          transform: translateX(0);
      }
    }
  
     /* showing icons only on sidebar */
    @media(max-width: 1200px){
      width: auto;
      padding: 20px 6px;
    }
  
     /* hiding full on small screen */
    @media(max-width: 768px){
      width: 260px;
      transform: translateX(-100%);
    }
  
    .sidebar-top{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 28px;
      padding-bottom: 4px;
      border-bottom: 1px solid #575777;
    }
  
    .sidebar-brand{
      display: flex;
      align-items: center;
      /* column-gap: 6px; */
    }
  
    .sidebar-brand-logo img{
      width: 100px;
      height: 100px;
      
      @media(max-width: 1200px){
          display: inline-block;
          width: 60px;
          height: 60px;
      }
  
      @media(max-width: 768px){
          display: inline-block;
      }
    }
  
    .sidebar-close-btn{
      position: absolute;
      right: 0px;
      top: 0px;
      background-color: #fbfbfc;
      /* width: 28px;
      height: 28px; */
      box-shadow: 0px 0px 4px rgba(71, 91, 232, 0.04);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #080808;
      transition: all 300ms ease-in-out;
      cursor: pointer;
      @media(max-width: 768px){
          display: inline-flex;
      }
      
      
      &:hover{
          opacity: 1;
      }
    }
  
    .menu-item{
      margin-bottom: 8px;
  
      &:last-child{
          margin-bottom: 0;
      }
  
      @media(max-width: 1200px){
          display: flex;
          align-items: center;
          justify-content: center;
      }
    }
  
    .menu-link{
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 11px;
      padding: 12px;
      border-radius: 12px;
      color: black;
      transition: all 300ms ease-in-out;
  
      @media(max-width: 1200px){
          padding: 2px;
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
      }
  
      @media(max-width: 768px){
          padding: 12px;
          border-bottom: 12px;
          width: 100%;
          height: 42px;
          justify-content: flex-start;
      }
  
      &:hover{
          background-color: #f6f6f6;
      }
  
      &.active{
          background-color: #424457;
          color: #ffffff;
          box-shadow: 0px 0px 4px rgba(71, 91, 232, 0.04);
      }
    }
  
    .mennu-link-icon{
      display: flex;
      align-items: center;
    }
  
    .menu-link-text{
      font-family: "Manrope", sans-serif;
      font-weight: 600;
      
      @media(max-width: 1200px){
          display: none;
      }
      @media(max-width: 768px){
          display: inline-block;
      }
    }
  
    .sidebar-body{
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  
    .sidebar-menu2{
      margin-top: auto;
    }
  }