.content-area-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* margin-top: 12px; */
    margin: 18px;
    gap: 16px;
  
    @media (max-width: 992px) {
      grid-template-columns: repeat(2, 1fr);
    }
  
    @media (max-width: 576px) {
      grid-template-columns: 100%;
    }
  
    .area-card {
      background-color: #fff;
      display: flex;
      align-items: center;
      border-radius: 5px;
      padding: 20px 16px;
      justify-content: space-between;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
      @media (max-width: 768px) {
        padding: 12px;
      }
  
      .info-title {
        color: #333;
        font-size: 18px;
        margin-bottom: 8px;
        font-weight: bold;
      }
  
      .info-value {
        color: #333;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 8px;
      }
  
      .info-text {
        color: #666;
        font-weight: 500;
      }
    }
  }
  
  .recharts-default-tooltip {
    padding: 4px 8px !important;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 4px 12px;
    border-radius: 4px;
    background-color: #fff !important;
    border: 1px solid black !important;
  }
  
  .recharts-tooltip-item-list {
    * {
      color: #333 !important;
      font-size: 14px;
      font-family: inherit !important;
      opacity: 0.9;
    }
  }