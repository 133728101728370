

.search-bar-container {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 10px;
}

.search-input {
  width: 80%;
  padding: 12px 16px;
  border: 2px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
  outline: none;
}
.search-bar-btn{
  width:20%;
  border-radius: 8px;
  background-color: #424457;
}
.search-bar-btn:hover{
  background-color: #5f617a;
}
.search-input:focus {
  border-color: #007bff;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
}

.dropdown-list {
  position: absolute;
  bottom: 100%; /* Positioned directly below the search input */
  left: 0;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  border-radius: 8px;
  border: 2px solid #ddd;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  /* z-index: 1000; */
  /* opacity: 0; */
  /* visibility: hidden; */
  /* transform: translateY(10px); */
  /* transition: opacity 0.3s, transform 0.3s; */
}

/* .dropdown-list.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
} */

.dropdown-item {
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 16px;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
  color: #007bff;
}

.dropdown-item-name {
  font-size: 16px;
}

.dropdown-item-status {
  font-size: 10px;
  /* font-size: 14px; Font size for status */
  color: #666; /* Gray color for status */
  display: flex; /* Flexbox for icon and status */
  align-items: center; /* Center items vertically */
}

.dropdown-item-status.active {
  color: #28a745; /* Green for active status */
}

.dropdown-item-status.away {
  color: #dc3545; /* Red for inactive status */
}
.dropdown-item-status.loggedOut {
  color: #6a7282; /* Red for inactive status */
}
.dropdown-item-dot{
    width: 8px;
    height: 8px;
    border-radius: 100%;
}

.no-users-found {
  padding: 10px; /* Padding for 'no users found' message */
  color: #666; /* Text color */
  text-align: center; /* Center-align the message */
  font-style: italic; /* Italic style for emphasis */
}
